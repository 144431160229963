import React from 'react'
import './homeButton.css'

const HomeButton = () => {
    return (


        <a href='#napra' className='homebutton-container'>
            <i class="fa fa-angle-double-up" aria-hidden="true" style={{ color: '#000' }} />
            N
        </a>

    )
}

export default HomeButton